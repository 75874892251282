@import 'styles/utils/mixins';

.desktopMenu {
  @include flex(row, center, center);

  gap: 48px;
  padding: 0;
  transition: margin $default-transition-time;
  height: 100%;

  li {
    display: inline-block;
    user-select: none;
    outline: none;
    margin: 0;
    padding: 0;

    li > svg {
      display: none;
    }

    &:last-child {
      position: relative;
      border-radius: $button-border-radius;
      border: 1px solid $gray0;
      color: $gray0;
      transition:
        border $default-transition-time,
        padding $default-transition-time,
        color $default-transition-time;
      opacity: 1 !important;
      transform: translateY(0);
      padding: 0;
      box-sizing: border-box;
      white-space: nowrap;

      span {
        transition: color $default-transition-time;
      }

      span::before {
        height: 0;
      }

      a {
        display: block;
        max-width: 153px;
        padding: 16px 32px;

        @include tablet {
          padding: 12px 24px;
        }
      }

      &:hover {
        will-change: border;
        border: 1px solid $yellow500;
        transition:
          border $default-transition-time,
          padding $default-transition-time,
          color $default-transition-time;

        span {
          will-change: color;
          color: $yellow500;
          transition: color $default-transition-time;
        }
      }
    }

    .underline {
      @include fontSize(16px, 150%);

      font-weight: 500;
      text-decoration: none;
      letter-spacing: 0.5px;
      transition: color $default-transition-time;

      &:hover {
        color: $gray500;
        transition: color $default-transition-time;
      }
    }
  }

  .nonClickableItem {
    @include flex(row, center, center);

    height: 100%;

    & > span {
      cursor: pointer;
    }
  }

  .dark {
    span {
      color: $gray0;
    }
  }

  .light {
    &:last-child {
      border-color: $gray1000;
    }

    span {
      color: $gray1000;
    }
  }

  @include tablet {
    gap: 16px;
  }

  @include below-tablet {
    display: none;
  }
}

.pageScrolled {
  .dark {
    span {
      color: $gray1000;
    }
  }

  li:not(:last-child) {
    .underline {
      &::before {
        background-color: $yellow500;
      }
    }
  }

  li {
    transition: box-shadow $shadow-time-for-yellow-button ease 0s;
  }

  li:last-child {
    color: $gray1000;
    position: relative;
    overflow: hidden;
    border: 1px solid $gray900;

    &:hover {
      border: 1px solid $yellow500;
      color: $gray1000;
      background-color: $yellow500;
      transform: scale(0.96);
      transition: transform 0.4s cubic-bezier(0, 0, 0.2, 1);

      span {
        color: $gray1000;
        transition: color 0.4s cubic-bezier(0, 0, 0.2, 1);
      }
    }

    a {
      transition: padding 0.4s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  span {
    color: $gray1000;

    &::before {
      background-color: $gray1000;
    }
  }
}
